import styled from "@emotion/styled"
import { Box } from "@theme-ui/components"

const PlayerContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};
  width: 100%;
  aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : "auto")};
  cursor: ${(props) => (props.disableInteraction ? "auto" : "pointer")};

  --plyr-color-main: #f7f4f3;
  --plyr-control-radius: 0;

  &.small-player {
    .plyr__controls {
      padding-left: 12px !important;
      padding-bottom: unset !important;
    }
  }

  &:hover {
    .plyr__controls {
      transform: translateY(0) !important;
    }
  }
  .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
    height: 100%;
  }
  .plyr__video-embed iframe,.plyr__video-wrapper--fixed-ratio video {
    position: fixed;
}

  .plyr:-webkit-full-screen.plyr--vimeo
    > .plyr__video-wrapper
    > .plyr__video-embed__container {
    transform: none !important;
    position: initial;
  }

  .plyr__controls {
    transform: translateY(100%);

    width: 100%;
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.mediaQueries.medium} {
      padding-left: 70px;
      padding-right: 70px;
      padding-bottom: 50px;
    }

    ${(props) => props.theme.mediaQueries.large} {
    }

    .plyr__time--duration {
      &::before {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .plyr__control {
      &:hover {
        background-color: transparent;
      }
    }

    .plyr__progress {
      margin-right: 20px;
      margin-left: 20px;
      input {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
        border-radius: 0 !important;
        &::-webkit-slider-runnable-track {
          border-radius: 0;
        }
        &::-webkit-slider-thumb {
          border-radius: 0 !important;
          -webkit-appearance: none;
          height: 5px;
          width: 5px;
          margin-top: 0px;
        }
      }
    }
  }
`
export default PlayerContainer
