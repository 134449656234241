const controls = `
<div class="plyr__controls">
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" role="presentation" style="width: 50px !important;">
          <style>
            .small { font: bold 9px sans-serif; }
          </style>

          <text class="small" y="12px">PAUSE</text>
        </svg>
        <svg class="icon--not-pressed" role="presentation" style="width: 50px !important;">
          <style>
            .small { font: bold 9px sans-serif; }
          </style>
          <text class="small" x="0" y="12px">PLAY</text>
        </svg>
    </button>
    <div class="plyr__time plyr__time--current small" aria-label="Current time">00'00</div>
    <div class="plyr__time plyr__time--duration small" aria-label="Duration">00'00</div>
    <div class="plyr__progress" style="flex: 1;">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <button type="button" class="plyr__control" data-plyr="fullscreen">
        <svg class="icon--pressed" role="presentation" style="width: 100px !important;">
          <style>
            .small { font: bold 9px sans-serif; }
          </style>
          <text class="small" y="12px">EXIT FULL SCREEN</text>
        </svg>
        <svg class="icon--not-pressed" role="presentation" style="width: 100px !important;">
          <style>
            .small { font: bold 9px sans-serif; text-align: right; }
          </style>
          <text class="small" y="12px">FULL SCREEN</text>
        </svg>
    </button>
</div>
`

export default controls
