import React, { useContext, useState, useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import { Flex, Box } from "@theme-ui/components"
import { useResponsiveValue } from "@theme-ui/match-media"
import { GatsbyImage } from "gatsby-plugin-image"
import get from "lodash/get"
import Layout from "../components/layout"
import FeaturedColors from "../components/featured-colors"
import Player from "../components/player"
import SEO from "../components/seo"
import Logo from "../assets/logo.svg"
import { Helmet } from "react-helmet"
import { useThemeUI } from "theme-ui"

import NavLink from "../components/nav-link"
import FeaturedCases from "../components/featured-case-nav"

import { InterfaceContext } from "../context/interface"

const RootIndex = ({ data, location, pageContext }) => {
  const { colorMode, setColorMode } = useThemeUI()
  useEffect(() => {
    setColorMode("default")
  }, [])

  const cases = data.featured.cases
  const { mainNav } = data

  const [activeIndex, setActiveIndex] = useState(0)
  const [c, setC] = useState(cases[0])

  const { locale, translationLinks, setTranslationLinks } =
    useContext(InterfaceContext)

  useEffect(() => {
    setTranslationLinks({
      "en-US": "",
      "sv-SE": "",
      "da-DK": "",
    })
  }, [])

  const next = () => {
    if (activeIndex === cases.length - 1) {
      setActiveIndex(0)
      setC(cases[0])
    } else {
      const nextIdx = activeIndex + 1
      setActiveIndex(activeIndex + 1)
      setC(cases[nextIdx])
    }
  }

  const [lang] = pageContext.locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }
  let now = new Date()

  const showNav = useResponsiveValue([false, true])
  return (
    <Layout
      nav={data.navigation}
      dropup={data.dropup}
      subNav={data.subnav}
      prefix={prefix}
      shouldShowNav={showNav}
      location={location}
      locale={pageContext.locale}
      renderAboveFold={() => {
        return (
          <Box
            pt={["0", "120px"]}
            sx={{
              color: "#fff",
              lineHeight: "25px",
              fontSize: "20px",
              fontWeight: 200,
              fontFamily: "pragmatica-extended",
              maxWidth: "750px",
            }}
          >
            <Box
              sx={{ whiteSpace: "pre-wrap" }}
              className="rte rte-h1-inline"
              dangerouslySetInnerHTML={{
                __html: data.intro.text.childMarkdownRemark.html,
              }}
            />
          </Box>
        )
      }}
    >
      <SEO locale={pageContext.locale} title={"Home"} />
      <Box
        sx={{
          display: ["none", "flex"],
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Player
            showPlay
            id={c.slug}
            thumbnail={c.thumbnail}
            aspectRatio={"2:1"}
            vimeoId={c.vimeoId}
          />
        </Box>
        <Flex
          sx={{
            transition: "background-color 500ms",
            backgroundColor: c.backgroundColor,
            alignItems: "center",
            flex: "1",
          }}
          px={"40px"}
          py={"40px"}
        >
          <Flex
            sx={{
              position: "relative",
              alignItems: "center",
              zIndex: "110",
              width: "calc(100% + 150px)",
              paddingRight: "140px",
            }}
          >
            <Box pt={"28px"} sx={{ flex: 1 }}>
              <Box
                sx={{
                  fontFamily: "pragmatica-extended, sans-serif",
                  a: { textDecoration: "none" },
                }}
                mb={20}
              >
                <Link to={`${prefix}cases/${c.slug}/`}>
                  <Box
                    sx={{
                      fontWeight: "200",
                      fontSize: "13px",
                      lineHeight: "18px",
                      textTransform: "uppercase",
                    }}
                  >
                    Case
                  </Box>
                  <Box
                    mb={"5px"}
                    sx={{
                      fontSize: "13px",
                      lineHeight: "18px",
                      transition: "all 200ms",
                      textTransform: "uppercase",
                      fontWeight: "400",
                    }}
                  >
                    {c.clientName}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Formular, sans-serif",
                      fontSize: 1,
                    }}
                  >
                    {c.title}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Formular, sans-serif",
                      fontSize: 1,
                    }}
                  >
                    {c.caseType}
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                sx={{
                  maxWidth: 250,
                  width: "100%",
                  height: "100%",
                  marginBottom: "15px",
                }}
              >
                <FeaturedColors
                  color1={c.color1}
                  color2={c.color2}
                  color3={c.color3}
                />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  fontSize: 3,
                  fontWeight: "300",
                  display: "inline-block",
                  fontFamily: "pragmatica-extended",
                  textAlign: "right",
                  textTransform: "uppercase",
                }}
                onClick={() => next()}
              >
                Next
              </Box>
              <Box
                sx={{
                  fontWeight: "400",
                  fontFamily: "pragmatica-extended",
                  textAlign: "right",
                  fontSize: 1,
                }}
              >
                ({activeIndex + 1}/{cases.length})
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box py={30} px={4} sx={{ display: ["flex", "none"], height: "100vh" }}>
        <Flex
          sx={{
            flexDirection: "column",
            flex: "1",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "95px" }} pt={"50px"} pb={"100px"}>
            <Link to={prefix}>
              <img src={Logo} />
            </Link>
          </Box>
          <Box pb={30}>
            {mainNav.items.map((i) => {
              if (i.internal?.type === "ContentfulFeaturedCases") {
                return (
                  <FeaturedCases
                    key={i.id}
                    item={i}
                    location={location}
                    prefix={prefix}
                  />
                )
              }
              if (i.internal.type === "ContentfulNavigationItem") {
                return (
                  <NavLink to={`${prefix}${i.link.slug}/`}>{i.title}</NavLink>
                )
              }

              /*  
              if (i.internal.type === "ContentfulPage") {
                return (
                  <NavLink key={i.id} to={`${prefix}${i.slug}`}>
                    {i.title+"villan"}
                  </NavLink>
                )
              }
              **/

              return null
            })}

            <NavLink to={`/contact`}>Contact</NavLink>
          </Box>
          <Box onClick={() => navigate(`/cases/${c.slug}/`)} mx={-4}>
            <GatsbyImage
              height="100%"
              width="100%"
              image={c.thumbnail.gatsbyImageData}
              alt=""
            />
            <Box pt={2} px={4} sx={{}}>
              <Box
                sx={{ fontFamily: "pragmatica-extended", fontWeight: "200" }}
              >
                Featured Case
              </Box>
              <Box
                sx={{ fontFamily: "pragmatica-extended", fontWeight: "400" }}
              >
                {c.title}
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Flex
            mt={3}
            mx={-3}
            px={3}
            sx={{
              flex: [1, "unset"],
              flexDirection: "column",
            }}
          >
            <Flex
              py="20px"
              sx={{
                flex: [1, "unset"],
                justifyContent: ["center", "flex-start"],
                flexDirection: ["column", "row"],
                fontWeight: "400",
                fontSize: [2, 1],
                textTransform: "uppercase",
                a: {
                  marginBottom: [2, 0],
                  marginRight: "30px",
                  textDecoration: "none",
                },
              }}
            >
              <Link to={`${prefix}services`}>Services</Link>
              <Link to={`${prefix}creative-session`}>Creative Session</Link>
            </Flex>
            <Flex
              sx={{
                flex: ["1", "unset"],
                flexDirection: ["column-reverse", "row"],
              }}
            >
              <Flex
                sx={{
                  flex: ["1", "unset"],
                  flexDirection: "column",
                  justifyContent: ["flex-end", "space-between"],
                }}
              >
                <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                  Copyright © {now.getFullYear()}
                </Box>
                <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                  All Rights Reserved
                </Box>
                <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                  <Link to={`${prefix}tos`}>Terms Of Service</Link>
                </Box>
                <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                <Link to={`${prefix}privacy`}>Privacy Policy</Link>
              </Box>
              </Flex>
              <Flex
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Flex
                  px={[0, "20px"]}
                  mb={[2, 0]}
                  sx={{
                    fontSize: [1, 0],
                    textTransform: "uppercase",
                    maxWidth: "200px",
                    div: {
                      marginRight: "3px",
                      marginLeft: "3px",
                      textDecoration: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: locale == "en-US" ? "bold" : "normal",
                      a: { textDecoration: "none" },
                    }}
                    fontWeight={locale === "en-US" && "bold"}
                  >
                    <Link
                      textDecoration="none"
                      to={`${translationLinks["en-US"]}`}
                    >
                      EN
                    </Link>
                  </Box>
                  /
                  <Box
                    sx={{
                      fontWeight: locale == "da-DK" ? "bold" : "normal",
                      a: { textDecoration: "none" },
                    }}
                    fontWeight={locale === "da-DK" && "bold"}
                  >
                    <Link
                      textDecoration="none"
                      to={`/da${translationLinks["da-DK"]}`}
                    >
                      DA
                    </Link>
                  </Box>
                  /
                  <Box
                    sx={{
                      fontWeight: locale == "sv-SE" ? "bold" : "normal",
                      a: { textDecoration: "none" },
                    }}
                    fontWeight={locale === "sv-SE" && "bold"}
                  >
                    <Link
                      textDecoration="none"
                      to={`/sv${translationLinks["sv-SE"]}`}
                    >
                      SV
                    </Link>
                  </Box>
                </Flex>
                <Flex
                  px={[0, "20px"]}
                  mb={[2, 0]}
                  sx={{
                    fontSize: [1, 0],
                    textTransform: "uppercase",
                    maxWidth: "200px",
                    a: {
                      marginRight: "3px",
                      marginLeft: "3px",
                      textDecoration: "none",
                    },
                  }}
                >
                  <a target="_blank" href="https://instagram.com">
                    Instagram
                  </a>
                  /
                  <a target="_blank" href="https://vimeo.com">
                    Vimeo
                  </a>
                  /
                  <a target="_blank" href="https://linkedin.com">
                    LinkedIn
                  </a>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    intro: contentfulTextColumn(
      node_locale: { eq: $locale }
      name: { eq: "Frontpage" }
    ) {
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Subnavigation" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    featured: contentfulFrontPageCases(node_locale: { eq: $locale }) {
      node_locale
      title
      cases {
        ...casePreviewFields
      }
    }
    mainNav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      node_locale
      ...navigationFields
    }
  }
`
