/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ canonicals, locale, metaImage, description, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            url
          }
        }
      }
    `
  )

  const [lang] = locale.split("-")

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content:
            (metaImage && `https:${metaImage}`) || site.siteMetadata.image,
        },
        {
          name: `og:image`,
          content: metaImage || site.siteMetadata.image,
        },
      ].concat(meta)}
    >
      {Object.entries(canonicals).map(([locale, canonical]) => {
        if (!locale) return
        const [lang] = locale.split("-")
        const path = lang !== "en" ? `/${lang}${canonical}` : canonical
        return (
          <link
            rel="alternate"
            hreflang={lang == "en" ? "x-default" : locale}
            href={`${site.siteMetadata.url}${path}`}
          />
        )
      })}
      <script type="application/ld+json">
        {`
        {
          "@context" : "https://schema.org",
          "@type" : "Organization",
          "name" : "Blonde Inc",
          "logo":"https://media-exp1.licdn.com/dms/image/C4D0BAQG0fmyMFG4Ftw/company-logo_200_200/0/1623394311639?e=2159024400&v=beta&t=B9Nh-pcVpxGJ5fGCTSXWM-Jiv-RjgpF1my5fqZYAxy0",
          "url" : "https://blonde.inc",
          "email": "DM@BLONDE.INC",
          "telephone": "+45 20265054",
          "address":
          {
          "@type": "PostalAddress",
          "addressLocality": "Copenhagen S",
          "postalCode": "2300",
          "streetAddress": "Lergravsvej 63, ST. V."
          },
          "sameAs" : [
          "https://www.facebook.com/blondeinc",
          "https://www.instagram.com/blonde___inc/"
          ]
          }
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  locale: `en-US`,
  meta: [],
  description: ``,
  canonicals: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
