import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Flex, Box } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import PlayerContainer from "../components/player-container"
import controls from "../utils/controls"
import "plyr/dist/plyr.css"

const Player = ({
  link,
  to,
  autoplay,
  showPlay,
  tempImage,
  thumbnail,
  vimeoId,
  aspectRatio,
  disableInteraction,
  id,
  small,
  url,
}) => {
  const [shouldLoad, setShouldLoad] = useState(false)
  const [plyr, setPlyr] = useState()

  const handleLoad = () => {
    if (link && to) {
      navigate(to)
    } else {
      setShouldLoad(true)
    }
  }

  useEffect(() => {
    if (tempImage && plyr) {
      plyr.pause()
    }
  }, [tempImage])

  useEffect(() => {
    if ((autoplay || shouldLoad) && (vimeoId || url)) {
      const Plyr = require("plyr")
      const play = new Plyr(`#P${id}-plyr${url ? " video" : ""}`, {
        ratio: aspectRatio || "16:9",
        loop: { active: autoplay },
        controls,
        autoplay: autoplay,
        muted: autoplay,
        tooltips: { controls: false },
        fullscreen: { enabled: true, fallback: true, iosNative: true },
      })

      if (vimeoId) {
        play.source = {
          type: "video",
          sources: [
            {
              src: vimeoId,
              provider: "vimeo",
            },
          ],
        }
      }

      play.on("ready", () => {
        !autoplay && play.play()
      })

      setPlyr(play)
    }
  }, [shouldLoad, vimeoId, aspectRatio, id, url])

  const ratio = aspectRatio ? aspectRatio.replace(":", "/") : "auto"

  return (
    <PlayerContainer
      aspectRatio={ratio}
      className={small ? "small-player" : ""}
      disableInteraction={disableInteraction}
      fullHeight={true}
      fullWidth={true}
    >
      {tempImage && !shouldLoad && (
        <Box
          onClick={handleLoad}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <img
            style={{ objectFit: "cover", objectPosition: "center" }}
            width="100%"
            height="100%"
            src={tempImage.file.url}
          />
        </Box>
      )}

      {shouldLoad || autoplay ? (
        <Box
          sx={{
            pointerEvents: disableInteraction ? "none" : "all",
            width: "100%",
            height: "100%",
            video: {
              objectFit: "cover",
            },
            ".plyr": {
              height: "100%",
            },
          }}
        >
          <div
            id={`P${id}-plyr`}
            style={{ height: "100%" }}
            className="blonde-player"
            data-plyr-config={`{"ratio": "${aspectRatio || "16:9"}"}`}
          >
            {url ? (
              <Box
                sx={{
                  height: "100%",
                  pointerEvents: autoplay ? "none" : "all",
                }}
              >
                <video
                  style={{ height: "100%" }}
                  preload="auto"
                  autoPlay={autoplay}
                  muted={autoplay}
                >
                  <source src={url} type="video/mp4" />
                </video>
              </Box>
            ) : (
              <iframe
                src={`https://player.vimeo.com/video/${vimeoId}?controls=0`}
                loading="lazy"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              />
            )}
          </div>
        </Box>
      ) : (
        <Flex
          onClick={handleLoad}
          sx={{
            position: "unset",
            top: 0,
            left: 0,
            width: "100%",
            video: {
              objectFit: "cover",
            },
          }}
        >
          {url && <video src={url} preload="metadata" />}
          {!!thumbnail && !tempImage && (
            <Box sx={{ width: "100%", height: "100%" }}>
              <GatsbyImage
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
                image={thumbnail.gatsbyImageData}
                alt=""
              />
            </Box>
          )}
          {showPlay && (
            <Flex
              sx={{
                color: "white",
                fontFamily: "pragmatica-extended, sans-serif",
                fontSize: "20px",
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                width: "100%",
                height: "100%",
                ":hover": {
                  opacity: 0.8,
                },
              }}
            >
              PLAY
            </Flex>
          )}
        </Flex>
      )}
    </PlayerContainer>
  )
}

export default Player
